import { __awaiter, __generator } from "tslib";
import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';
export var createGoToPricingPlanPickerHandler = function (handleGoToPricingPlanPicker, instance) {
    return function (channelId) { return __awaiter(void 0, void 0, void 0, function () {
        var pricingPlansAPI, plansIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pricingPlansAPI = new PricingPlansAPI(instance);
                    return [4 /*yield*/, pricingPlansAPI.getConnectedPlans(channelId)];
                case 1:
                    plansIds = _a.sent();
                    handleGoToPricingPlanPicker(plansIds.map(function (plan) { return plan.id; }));
                    return [2 /*return*/];
            }
        });
    }); };
};
