import { __awaiter, __generator } from "tslib";
import axios from 'axios';
import { getHydratedData } from '../../../widget/redux/client/hydrated-data/hydrated-data';
export var fetchVODExperiments = function () {
    return axios
        .get('https://www.wix.com/_api/wix-laboratory-server/laboratory/conductAllInScope', {
        params: {
            scope: 'vod',
        },
    })
        .then(function (response) { return response.data; });
};
export var getVODExperiments = function (state) { return __awaiter(void 0, void 0, void 0, function () {
    var experiments, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                experiments = getHydratedData(state).experiments;
                _a = experiments;
                if (_a) return [3 /*break*/, 2];
                return [4 /*yield*/, fetchVODExperiments()];
            case 1:
                _a = (_b.sent());
                _b.label = 2;
            case 2: return [2 /*return*/, _a];
        }
    });
}); };
